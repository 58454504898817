import React from 'react';
import ReactDOM from 'react-dom';
import { Dialog } from '@progress/kendo-react-dialogs';

export function kendoAlert(text, title, buttonText, onCloseCallback, isError = false) {
    const kendoAlertContainer = document.createElement("div");
    document.body.append(kendoAlertContainer);

    const onClose = () => {
        //Destroy dialog component and remove container from DOM
        ReactDOM.unmountComponentAtNode(kendoAlertContainer);
        kendoAlertContainer.remove();        
    }

    const onCloseWithCallback = () => {
        onClose();
        //Call the callback passed as a parameter, if any
        if (onCloseCallback) onCloseCallback();
    }

    if (!title) title = '\xa0';
    if (!buttonText) buttonText = 'OK';

    let newText = text.split('\n').map((item, i) => <p key={i} style={{ textAlign: 'center', color: (isError ? 'red' : '') }}>{item}</p>);

    const dialogJsx = (
        <Dialog title={title} onClose={onClose}>
            <div style={{ padding: '0 10px', maxWidth: '500px' }}>
                {newText}
                <p style={{ textAlign: 'center' }}>
                    <button className="k-button" style={{ minWidth: '70px' }} onClick={onCloseWithCallback}>{buttonText}</button>
                </p>
            </div>
        </Dialog>
    );

    ReactDOM.render(dialogJsx, kendoAlertContainer);
}

export function kendoConfirm(text, title, onConfirmCallback, onCancelCallback, width = null, okayButtonText = 'OK', cancelButtonText = 'Cancel') {
    const kendoAlertContainer = document.createElement("div");
    document.body.append(kendoAlertContainer);

    const onConfirm = () => {
        //Destroy dialog component and remove container from DOM
        ReactDOM.unmountComponentAtNode(kendoAlertContainer);
        kendoAlertContainer.remove();

        //Call the callback passed as a parameter, if any
        if (onConfirmCallback) onConfirmCallback();
    }

    const onCancel = () => {
        //Destroy dialog component and remove container from DOM
        ReactDOM.unmountComponentAtNode(kendoAlertContainer);
        kendoAlertContainer.remove();

        if (onCancelCallback) onCancelCallback();
    }

    if (!title) title = '\xa0';    

    const dialogJsx = (
        <Dialog title={title} closeIcon={false}>
            <div style={{ padding: '0 10px', maxWidth: '500px', width: (width ? width +'px': '') }}>
                <p style={{ textAlign: 'center' }} dangerouslySetInnerHTML={{ __html: text }} />
                <p style={{ textAlign: 'center' }}>
                    <button className="k-button" onClick={onConfirm} style={{ background: 'cornflowerblue', width: 80, color: 'white', marginRight: 5 }}>{okayButtonText}</button>
                    <button className="k-button" onClick={onCancel} style={{ border: '1px solid #d0cdcd', width: 80 }}>{cancelButtonText}</button>
                </p>
            </div>
        </Dialog>
    );

    ReactDOM.render(dialogJsx, kendoAlertContainer);
}